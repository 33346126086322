// import { ThemeProvider } from "styled-components";
// import { Router, Route, Switch } from "react-router-dom";
// import history from "./history";
// import {
//     LandingPage,
//     AboutPage,
//     MusicPage,
//     ProjectsPage,
//     ClimbingPage,
// } from "./containers";
// import { ThemeManager } from "./functions/themeManager";
// import { containers, light_mode, dark_mode } from "./styles";
// import { Toggle } from "./components/Toggle";

// function App() {
//     const themeProp = ThemeManager();
//     const themeMode = themeProp.theme ? dark_mode : light_mode;

//     return (
//         <ThemeProvider theme={themeMode}>
//             <containers.SiteContainer>
//                 <Toggle {...themeProp} />
//                 <Router history={history}>
//                     <Switch>
//                         <Route exact path="/" component={LandingPage} />
//                         <Route path="/kurzgesagt" component={AboutPage} />
//                         <Route path="/projects" component={ProjectsPage} />
//                         <Route path="/muusika" component={MusicPage} />
//                         <Route path="/escalade" component={ClimbingPage} />
//                     </Switch>
//                 </Router>
//             </containers.SiteContainer>
//         </ThemeProvider>
//     );
// }

// export default App;

import React from "react";
import styled from "styled-components";
import crosswalk from "./assets/crosswalk.jpg";
import dreamcatcher from "./assets/dreamcatcher.jpg";
import quarryRock from "./assets/quarry-rock.jpg";
import seaToSky from "./assets/sea-to-sky.jpg";
import grandWallFlowers from "./assets/grand-wall-flowers.jpg";
import orfordBouldering from "./assets/orford-bouldering.jpg";
import grandWall from "./assets/grand-wall.jpg";
import universiteDeBordeaux from "./assets/universite-de-bordeaux.jpg";
import maxAndMike from "./assets/max-and-mike.png";

import resume from "./assets/resume2024.pdf";

const BackgroundImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transition: opacity 2s cubic-bezier(0.1, 0, 0.2, 1);
  opacity: 0;

  pointer-events: none;
  select: none;

  &.show {
    opacity: 1;
  }
`;

const Container = styled.div`
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MessageContainer = styled.a`
  position: fixed;
  top: 2rem;
  right: 4rem;

  font-size: 3rem;
  font-weight: 900;
  border: 3px solid white;
  padding: 0.4rem;

  mix-blend-mode: difference;
  color: white;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.05);

  :hover {
    mix-blend-mode: difference;
    color: red;
    background-color: blue;
    border-color: blue;
  }

  @media (max-width: 1000px) {
    mix-blend-mode: normal;
    background: rgba(0, 0, 0, 0.1);
    color: white;
  }

  @media (max-width: 700px) {
    width: 20rem;
    left: calc(50vw - 10rem);
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 1rem;

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 800;

  :hover {
    color: blue;
    text-decoration: underline yellow 0.2rem;
  }
`;

const Copyright = styled.div`
  color: white;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 800;

  /* align text to bottom */
  margin-top: auto;
`;

const backgrounds = [
  crosswalk,
  dreamcatcher,
  quarryRock,
  seaToSky,
  grandWallFlowers,
  orfordBouldering,
  grandWall,
  universiteDeBordeaux,
  maxAndMike,
];

const backgroundInterval = 5000;

function App() {
  // Index of the current background for A and B
  const [indexA, setIndexA] = React.useState(0);
  const [indexB, setIndexB] = React.useState(1);
  // Toggle show for A or B background
  const [showA, setShowA] = React.useState(true);
  const timeoutId = React.useRef<NodeJS.Timeout>();

  const toggleBackground = React.useCallback(
    (increment = 1) => {
      if (showA) {
        setIndexB((indexA + increment + backgrounds.length) % backgrounds.length);
      } else {
        setIndexA((indexB + increment + backgrounds.length) % backgrounds.length);
      }
      setShowA((show) => !show);
    },
    [showA, indexA, indexB]
  );

  function handleKeyPress(event: KeyboardEvent) {
    if (event.key === "s" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      return;
    }

    if (event.key === "ArrowRight") toggleBackground();
    if (event.key === "ArrowLeft") toggleBackground(-1);
    if (event.key === "ArrowDown") toggleBackground();
    if (event.key === "ArrowUp") toggleBackground(-1);
  }

  function handleClick() {
    toggleBackground();
  }

  React.useEffect(() => {
    timeoutId.current = setInterval(() => {
      toggleBackground();
    }, backgroundInterval);

    return () => clearInterval(timeoutId.current!);
  }, [toggleBackground]);

  return (
    <Container tabIndex={0} onKeyDown={handleKeyPress}>
      <div onMouseDown={handleClick} onContextMenu={(event) => event.preventDefault()}>
        <BackgroundImage className={showA ? "show" : ""} src={backgrounds[indexA]} />
        <BackgroundImage className={showA ? "" : " show"} src={backgrounds[indexB]} />
      </div>
      <MessageContainer href="https://github.com/chetbae">
        Under
        <br />
        Construction!
        <br />
        Check
        <br />
        back
        <br />
        soon.
      </MessageContainer>
      <Footer>
        <StyledLink href="https://github.com/chetbae">GitHub</StyledLink>

        <StyledLink href="https://www.linkedin.com/in/chetbae/">LinkedIn</StyledLink>

        <StyledLink href={resume} target="_blank">
          CV
        </StyledLink>

        <Copyright>© 2024 maxzhang.ca</Copyright>
      </Footer>
    </Container>
  );
}

export default App;
